import { formatDateToLocal } from '../../common/format'

export const SET_STEP_DATA = 'form//SET_STEP_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export const SET_DATA_MODELS = 'form//SET_DATA_MODELS'

export const PREFILL_CONNECT_APP = 'form//PREFILL_CONNECT_APP'

export function prefillConnectAppData(customer, vehicle, appId) {
  return {
    type: PREFILL_CONNECT_APP,
    payload: {
      customer,
      vehicle,
      appId,
    },
  }
}

export function setStepData(step, data) {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  }
}

export function setDataModels(data) {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  }
}

export function getDataModels(store) {
  return store.form.dataModels
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getStepData(store, step) {
  return store.form.stepData[step]
}

export function getFormData(store) {
  return store.form
}

const initialState = {
  stepData: {},
  dataModels: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        stepData: {},
      }

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      }
    case SET_DATA_MODELS:
      return {
        ...state,
        dataModels: action.payload,
      }

    case PREFILL_CONNECT_APP: {
      const { vehicle, customer } = action.payload

      const {
        address = {},
        contactData = {},
        firstName,
        lastName,
        salutation,
        dateOfBirth,
      } = customer

      return {
        ...state,
        stepData: {
          ...state.stepData,
          2: {
            ...(state.stepData[1] || {}),
            eMailAddress: contactData.email,
            eMailAddressRepetition: contactData.email,
            firstName,
            lastName,
            salutation,
            dateOfBirth: formatDateToLocal(dateOfBirth),
            mobilePhoneNumber:
              contactData.telephoneCountryCode + contactData.telephoneNumber,
            // FES expects the name to be English one, Storage service gives us
            // German one. Since STIN is only used in Germany (the country
            // selection is locked) we can override this.
            // country: action.payload.customer.address.country,
            country: 'Germany',

            cityOfResidence: address.city,
            houseNumber: address.houseNumber,
            street: address.street,
            postalCode: address.zipCode,

            licensePlate: vehicle.licensePlate,

            appId: action.payload.appId,
          },
        },
      }
    }

    default:
      return state
  }
}
