import React from 'react'
import { Accordion } from '@vwfs-bronson/bronson-react'
import { differenceInMilliseconds } from 'date-fns'
import { HtmlContent } from '../../components/HtmlContent'

import { THIRD_DRIVER } from '../../resources'
import { getProductHomepage } from '../../config'

const ONE_DAY = 1000 * 60 * 60 * 24

export const setMyselfErrorMsg = (errors, message) => {
  const errorsData = errors
  if (message) {
    errorsData.myself = message
    return null
  }

  delete errorsData.myself

  return null
}

export const setFirstInsuranceHolder = (setFieldValue, index, values) => {
  const fieldsNames = ['firstName', 'lastName', 'dateOfBirth']

  fieldsNames.forEach((item) => {
    const value = values === '' ? '' : values[item]
    setFieldValue(`insuranceHolders[${index}].${item}`, value)
  })
}

export const prepareInsuranceHolders = (quantity) => {
  const payload = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  }

  return Array.from({ length: quantity }).map(() => ({
    ...payload,
  }))
}

export const insuranceHoldersGenerator = (
  numberOfPeopleToInsure,
  insuranceHolders
) => {
  const newInsuranceHolders =
    insuranceHolders || prepareInsuranceHolders(numberOfPeopleToInsure)

  if (
    insuranceHolders === undefined &&
    newInsuranceHolders.length === numberOfPeopleToInsure
  ) {
    return prepareInsuranceHolders(numberOfPeopleToInsure)
  }
  if (
    newInsuranceHolders.length !== numberOfPeopleToInsure &&
    newInsuranceHolders.length < numberOfPeopleToInsure
  ) {
    return [
      ...newInsuranceHolders,
      ...prepareInsuranceHolders(
        numberOfPeopleToInsure - newInsuranceHolders.length
      ),
    ].map((el) => ({
      firstName: el.firstName,
      lastName: el.lastName,
      dateOfBirth: el.dateOfBirth,
    }))
  }
  return [...newInsuranceHolders].splice(0, numberOfPeopleToInsure)
}

export function restrictOnChange(regex, originalHandler) {
  return function restrictOnChangeHandler(e) {
    const event = {
      target: {
        name: e.target.name,
        value: e.target.value.replace(regex, ''),
      },
    }
    originalHandler(event)
  }
}

export const initialValue = (value, initValue) => {
  if (value === undefined) {
    return initValue
  }
  return value
}

export function formatInteger(number) {
  if (number !== undefined) {
    return number
      .toFixed(2)
      .toString()
      .replace(/\./g, ',')
      .replace(/\D,+/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  return null
}

export const formatWithUnit = (val, unit) =>
  formatInteger(val) ? `${formatInteger(val)} ${unit}` : ''

export function renderAccordion(content) {
  if (typeof content === 'string') {
    return null
  }
  return (
    <Accordion>
      {content.map((item) => {
        let { text } = item
        text = text.replace(
          '{PRODUCT_THIRD_DRIVER_URL}',
          getProductHomepage(THIRD_DRIVER)
        )
        return (
          <Accordion.Item key={item.title} title={item.title}>
            <HtmlContent>{text}</HtmlContent>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}

export function scrollToTargetAdjusted(targetElementClass) {
  const STICKY_HEADER_HEIGHT = 100
  const offsetPosition =
    document.querySelector(targetElementClass).getBoundingClientRect().top -
    STICKY_HEADER_HEIGHT
  if (document.documentElement.style.scrollBehavior === undefined) {
    window.scrollTo(0, offsetPosition)
  } else {
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}

export function getWeekNumber(_d) {
  const d = new Date(Date.UTC(_d.getFullYear(), _d.getMonth(), _d.getDate()))
  const monthIndex = d.getUTCMonth()
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
  return monthIndex === 0 && weekNumber >= 52 ? 1 : weekNumber
}

export function setDateFromWeekNumber(d) {
  const [week, year] = d.split('/')
  const expectedDeliveryDate = new Date(year, 0, (week - 1) * 7)
  expectedDeliveryDate.setDate(
    // set week day to friday
    expectedDeliveryDate.getDate() - expectedDeliveryDate.getDay() + 5
  )
  return expectedDeliveryDate
}

export function getPrice(customerType, contractGrossPrice, contractNetPrice) {
  if (customerType === 'private') {
    return contractGrossPrice
      ? `${formatInteger(contractGrossPrice)} Kč s DPH`
      : ''
  }
  return contractGrossPrice && contractNetPrice
    ? `${formatInteger(contractNetPrice)} Kč bez DPH / ${formatInteger(
        contractGrossPrice
      )} Kč s DPH`
    : ''
}

export function countDays(date1, date2) {
  return (
    differenceInMilliseconds(parseDate(date2), parseDate(date1)) /
    (24 * 60 * 60 * 1000)
  )
}

export function parseDate(value) {
  const date = value.split('.')
  return new Date(date[2], date[1] - 1, date[0])
}

export function formatDateToISO(date) {
  const parts = date.split('.')
  return `${parts[2]}-${parts[1]}-${parts[0]}`
}

export function formatDateToLocal(date) {
  try {
    const desiredDate = date.split('T')[0]
    const parts = desiredDate.split('-')
    return `${parts[2]}.${parts[1]}.${parts[0]}`
  } catch (e) {
    return ''
  }
}

export function millisecondsToIsoFormat(milliseconds) {
  return `P${Math.round(milliseconds / ONE_DAY)}D`
}

export function numberOfDaysToIsoFormat(numberOfDays) {
  return `P${Math.round(numberOfDays)}D`
}
