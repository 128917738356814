export const FETCH_GET_ORDER_PENDING = 'order//FETCH_GET_ORDER_PENDING'
export const FETCH_GET_ORDER_DONE = 'order//FETCH_GET_ORDER_DONE'
export const FETCH_GET_ORDER_ERROR = 'order//FETCH_GET_ORDER_ERROR'

export function pending() {
  return {
    type: FETCH_GET_ORDER_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_ORDER_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_ORDER_ERROR,
    payload: {
      err,
    },
  }
}

export function getOrderData(store) {
  return store.order.data || ''
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_ORDER_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_ORDER_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_ORDER_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
