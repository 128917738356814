/**
 * This file needs to use commonjs export syntax in order to
 * work as part of the build tool
 */

const brand = window.getBrand()
const brandName = brand === 'vw5' ? 'vw' : brand

const isVwfs = () => brand === 'vwfs'
const DATE_FORMAT = 'DD.MM.YYYY'

const isProd = () => {
  if (process.env.REACT_APP_STAGE === 'prod') return true
  return false
}

const getProduct = () => {
  if (process.env.NODE_ENV === 'development') {
    const customProduct = new URL(window.location).searchParams.get('product')
    if (customProduct) {
      window.reactConfig.product = customProduct
      return customProduct
    }
  }
  const { product } = window.reactConfig || {}

  if (product === '%product%') return ''
  return product
}

const getProductForDTM = (product, pointOfSale = 'private') => {
  switch (product) {
    case 'third-driver':
      return '3rd driver'
    case 'test-driver':
      return `${pointOfSale} test drive`
    case 'car-rental':
      return 'car rental'
    case 'young-driver':
      return 'young-driver'
    default:
      return ''
  }
}

const getHubUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return `/`
  }

  return (((window || {}).reactConfig || {}).urls || {}).hub
}

function getProductHomepage(product) {
  let connectApp = ''
  if (document.location.search.indexOf('e=app') !== -1) {
    const id = document.location.search.match('i=([0-9a-z-]+)')[1]
    connectApp =
      process.env.NODE_ENV === 'development'
        ? `&e=app&i=${id}`
        : `?e=app&i=${id}`
  }
  if (process.env.NODE_ENV === 'development') {
    if (product === 'hub') {
      return '/'
    }
    const isVwPath = /vw(\/|$)/.test(window.location.pathname)
    return `${isVwPath ? '/vw' : ''}/form/1?product=${product}${connectApp}`
  }

  return `${
    (((window || {}).reactConfig || {}).urls || {})[product]
  }${connectApp}`
}

const getProductUrl = (product, step = 1) => {
  if (process.env.NODE_ENV === 'development') {
    return `/form/${step}?product=${product}`
  }

  return `/form/${step}`
}

const DEFAULT_ANALYTICS = {
  country: 'DE',
  language: 'de',
  brand: 'vw',
  platform: 'shortterminsurance',
  product: getProductForDTM(getProduct()),
  platformvariation: 'standalone',
}

module.exports.DEFAULT_ANALYTICS = DEFAULT_ANALYTICS
module.exports.DATE_FORMAT = DATE_FORMAT
module.exports.brandName = brandName
module.exports.isVwfs = isVwfs
module.exports.getProduct = getProduct
module.exports.getProductUrl = getProductUrl
module.exports.getHubUrl = getHubUrl
module.exports.isProd = isProd
module.exports.getProductHomepage = getProductHomepage
module.exports.getProductForDTM = getProductForDTM
