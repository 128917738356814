import { createStore, combineReducers } from 'redux'

import formReducer from './features/form'
import quoteReducer from './features/quote.redux'
import documentBundleReducer from './features/document-bundle.redux'
import policyReducer from './features/policy.redux'
import orderReducer from './features/order.redux'
import summaryPageReducer from './features/summary-page.redux'
import paymentReducer from './features/payment.redux'

const SESSION_CACHE_KEY = 'sessionCache'

const isFormPage = window.location.pathname.includes('/form')
if (!isFormPage) {
  sessionStorage.removeItem(SESSION_CACHE_KEY)
}

const preloadedStateJson = sessionStorage.getItem(SESSION_CACHE_KEY)
const preloadedState = preloadedStateJson
  ? JSON.parse(preloadedStateJson)
  : undefined

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    quote: quoteReducer,
    documentBundle: documentBundleReducer,
    policy: policyReducer,
    order: orderReducer,
    summaryPage: summaryPageReducer,
    payment: paymentReducer,
  }),
  preloadedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

store.subscribe(() => {
  sessionStorage.setItem(SESSION_CACHE_KEY, JSON.stringify(store.getState()))
})

export { store }
