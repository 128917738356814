/**
 * This file needs to use commonjs export syntax in order to
 * work as part of the build tool
 */

const brandNames = {
  vw: 'Volkswagen',
}

const THIRD_DRIVER = 'third-driver'
const YOUNG_DRIVER = 'young-driver'
const TEST_DRIVER = 'test-driver'
const CAR_RENTAL = 'car-rental'

const YOUNG_DRIVER_BIRTHDATE = '1997-01-01'
const ADULT_DRIVER_BIRTHDATE = '1980-01-01'
const ADULT_DRIVER_BIRTHDATE_FORM_INITIAL = '01.01.1980'

const allProductsObj = {
  THIRD_DRIVER,
  YOUNG_DRIVER,
  TEST_DRIVER,
  CAR_RENTAL,
}

const pointOfSaleTypes = {
  PRIVATE: 'private',
  DEALER: 'dealer',
}

const productVariants = {
  BASIC: 'S',
  PREMIUM: 'M',
}
const productLabels = {
  'test-driver': 'STIN-TestDrive',
  'third-driver': 'STIN-3rdDriver',
  'car-rental': 'STIN-RentalCar',
  'young-driver': 'STIN-YoungDriver',
}

const allProductsArray = Object.values(allProductsObj)

module.exports.brandNames = brandNames
module.exports.THIRD_DRIVER = THIRD_DRIVER
module.exports.YOUNG_DRIVER = YOUNG_DRIVER
module.exports.TEST_DRIVER = TEST_DRIVER
module.exports.CAR_RENTAL = CAR_RENTAL
module.exports.allProductsObj = allProductsObj
module.exports.allProductsArray = allProductsArray
module.exports.YOUNG_DRIVER_BIRTHDATE = YOUNG_DRIVER_BIRTHDATE
module.exports.ADULT_DRIVER_BIRTHDATE = ADULT_DRIVER_BIRTHDATE
module.exports.ADULT_DRIVER_BIRTHDATE_FORM_INITIAL =
  ADULT_DRIVER_BIRTHDATE_FORM_INITIAL
module.exports.pointOfSaleTypes = pointOfSaleTypes
module.exports.productVariants = productVariants
module.exports.productLabels = productLabels
