/* eslint-disable global-require */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'cz',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default (brandName = 'vw6') =>
  new Promise((res) => {
    const namespaces = [
      'page',
      'validation',
      'form',
      'app',
      'step1',
      'step2',
      'step3',
      'step4',
      'error',
      'landing-page',
    ]
    Promise.all(
      namespaces.map((item) =>
        import(`../../resources/${brandName}/content/${item}.json`).then(
          (module) => {
            i18n.addResourceBundle('cz', item, module.default, true, true)
          }
        )
      )
    ).then(res)
  })
