export const FETCH_GET_POLICY_PENDING = 'policy//FETCH_GET_POLICY_PENDING'
export const FETCH_GET_POLICY_DONE = 'policy//FETCH_GET_POLICY_DONE'
export const FETCH_GET_POLICY_ERROR = 'policy//FETCH_GET_POLICY_ERROR'

export function pending() {
  return {
    type: FETCH_GET_POLICY_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_POLICY_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_POLICY_ERROR,
    payload: {
      err,
    },
  }
}

export function getPolicyData(store) {
  return store.policy.data.id || ''
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_POLICY_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_POLICY_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_POLICY_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
