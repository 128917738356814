export const SET_PAYMENT_STATUS = 'payment//SET_PAYMENT_STATUS'

export function getPaymentData(state) {
  return state.payment
}

export function setPaymentStatus(paymentStatus, paymentData) {
  return {
    type: SET_PAYMENT_STATUS,
    payload: {
      status: paymentStatus,
      data: paymentData,
    },
  }
}

const initialState = {
  status: null,
  data: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
