export const FETCH_GET_DOCUMENT_BUNDLE_PENDING =
  'document//FETCH_GET_DOCUMENT_BUNDLE_PENDING'
export const FETCH_GET_DOCUMENT_BUNDLE_DONE =
  'document//FETCH_GET_DOCUMENT_BUNDLE_DONE'
export const FETCH_GET_DOCUMENT_BUNDLE_ERROR =
  'document//FETCH_GET_DOCUMENT_BUNDLE_ERROR'

export function pending() {
  return {
    type: FETCH_GET_DOCUMENT_BUNDLE_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_DOCUMENT_BUNDLE_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_DOCUMENT_BUNDLE_ERROR,
    payload: {
      err,
    },
  }
}

export function getDocumentBundleData(store) {
  return store.documentBundle.data.url || ''
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_DOCUMENT_BUNDLE_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_DOCUMENT_BUNDLE_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_DOCUMENT_BUNDLE_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
